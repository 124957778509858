<template>
  <section>
    <wrapper-collapse  title="Печать предложения по детализации квоты приема на целевое обучение" initial-open  active-class="border-active-content">
    <AlertWarning v-if="!blocked">
      <p>Загрузка скана осуществляется после блокировки</p>
    </AlertWarning>
    <!--          :loading="downloadNow"-->
    <v-btn
      :disabled="downloadNow"
      class="blue__v-btn mt-3 px-2 py-2"
      @click="print"
      v-if="blocked"
    >
      <v-icon size="24" color="white">mdi-file-document-outline</v-icon><span class="nav-item-kcp_title">Печать</span>
    </v-btn>
    <div v-if="blocked" class="file mt-4">
      <div class="file-view">
        <div class="file-view__name">
          <span v-html="item.name"></span>
          <button
            v-if="alreadyUploadedFile.length !== 0 && alreadyUploadedFile[0].fileId"
            class="file-view__download-file" @click="getUploadedFile"
          >
            просмотр загруженного файла
            <v-progress-circular
              v-if="uploadedFileLoading"
              color="#eb722e"
              indeterminate
              size="10"
              width="2"
            ></v-progress-circular>
          </button>
        </div>
        <div class="file-view__actions mt-2">
          <v-btn
            v-if="!modeEdit"
            class="white__v-btn"
            @click="modeEdit = !modeEdit">
            {{ alreadyUploadedFile.length !== 0 && alreadyUploadedFile[0].fileId ? 'Заменить' : 'Загрузить' }}
          </v-btn>
        </div>
      </div>
      <div v-if="modeEdit" class="file-edit">
        <v-autocomplete
          v-model="idTypeUploadedFile"
          :items="typeUploadedFiles"
          placeholder="Выберите вид прилагаемого файла"
          clearable
          dense
          no-data-text="По вашему запросу ничего нет"
        ></v-autocomplete>
        <v-file-input v-model="fileToUpload" :rules="rules" accept="application/pdf" label="Выбрать файл pdf..."/>
        <v-btn
          v-if="validate"
          :disabled="!canUpload || !idTypeUploadedFile "
          class="blue__v-btn"
          :loading="uploadToServNow"
          @click="uploadFile"
        >Отправить предложение</v-btn>
        <v-btn class="white__v-btn ml-2" @click="modeEdit = !modeEdit">Отмена</v-btn>
      </div>
    </div>
    </wrapper-collapse>
  </section>
</template>

<script>
import axios from "axios";
import {mapActions, mapGetters} from "vuex";
import AlertWarning from "@/components/alerts/AlertWarning";
import request from "@/services/request";
import WrapperCollapse from "@/components/wrappers/WrapperCollapse";
const YEAR = 2023;
export default {
  name: "QuotaPrint",
  components: {WrapperCollapse, AlertWarning},
  data() {
    return {
      item: {
        name: 'ВНИМАНИЕ!!! Скан должен содержать формы, которые выходят на печать, подписанные руководителем организации с печатью.<br>' +
          'Скан грузится одним pdf-файлом размером до 50 МБ', fileType: ''
      },
      allDataByFounderId: [],
      downloadNow: false,
      fileToUpload: null,
      blocked: false,
      modeEdit: false,
      uploadNow: false,
      uploadedFileLoading: false,
      validate: null,
      idTypeUploadedFile: null,
      alreadyUploadedFile: [],
      typeUploadedFiles: [
        {value: `DISTRIBUTION_MAIN_SCAN_${YEAR}`, text: 'Предложение по детализации квоты приема на целевое обучение'},
        {value: `DISTRIBUTION_LETTER_OUT_OF_SCAN_${YEAR}`, text: 'Письмо об отсутствии предложений по детализации квоты приема на целевое обучение'},
      ],
      uploadToServNow: false,
      rules: [
        value => !value || value.size < 20000000 || 'Размер файла не должен превышать 50 МБ!',
        value => !value || value.type === 'application/pdf' || 'Допустимы файлы только в pdf формате',
        value => {
          if (!value) {
            this.validate = null;
            return false;
          } else if (value.type === 'application/pdf') {
            this.validate = true;
            return true;
          } else {
            this.validate = false;
            return false;
          }
        }
      ],
      canUpload: true,
    }
  },
  computed: {
    ...mapGetters('keycloak', {
      getAuthData: 'getAuthData',
      getUser: 'getUser',
      getUserRoles: 'getUserRoles',
      isRegion: 'isRegion'
    }),
  },
  mounted() {
    this.getFounderStatus();
    this.getUploadedScan();
  },
  methods: {
    ...mapActions('notifications', {addNotification: 'addNotification'}),
    async getFounderStatus() {
      try {
        let uri = `/api/spr/founder/founderId?founderId=${this.getUser.founderID}`
        if (this.isRegion) uri = `/api/spr/region/regionId?regionId=${this.getUser.regionID}`
        const res = await request({
          endpoint: uri,
          method: 'get',
          token: `${this.getAuthData.token}`
        });
        if (res.status === 200 && res.data.founderStatus.founderWorkflow === 'SENT') {

          this.blocked = true

          console.log('getFounderStatus успех');
        }
      } catch (e) {
        console.log(e);
      }
    },
    async getUploadedScan() {
      try {
        let uri;
        if (this.isRegion) {
          uri = `/api/moduledatagather/scan?regionId=${this.getUser.regionID}`
        } else {
          uri = `/api/moduledatagather/scan?founderId=${this.getUser.founderID}`
        }
        const res = await request({
          endpoint: uri,
          method: 'get',
          token: `${this.getAuthData.token}`
        });
        if (res.status === 200 && res.data.length !== 0) {
          console.log('getUploadedScan успех');
          this.alreadyUploadedFile = res.data.filter(el => el.scanType === `DISTRIBUTION_MAIN_SCAN_${YEAR}`||
              el.scanType === `DISTRIBUTION_LETTER_OUT_OF_SCAN_${YEAR}`)
        } else {
          console.log(res.data.length);
        }
      } catch (e) {
        console.log(e);
      }
    },

    //печать файла ////////////////////////////////////////
    async print() {
      this.downloadNow = true;
      let uri = `/api/moduledatagather/report/pdf?founderId=${this.getUser.founderID}`;
      if (this.isRegion) uri = `/api/moduledatagather/report/pdf?regionId=${this.getUser.regionID}`
      let responseAxios = await axios.get(uri, {
        responseType: 'blob',
        token: `${this.getAuthData.token}`
      })
      this.downloadNow = false;
      this.saveByteArray("ReportFunc.pdf", responseAxios.data)
    },

    saveByteArray(reportName, byte) {
      let blob = new Blob([byte], {type: "application/pdf"});
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = reportName;
      link.click();
    },
    //конец печати файла ///////////////////////////////////////

    async uploadFile() {
      if (this.fileToUpload.size < 20000000) {
        this.uploadToServNow = true;
        if (this.fileToUpload && this.validate && this.idTypeUploadedFile) {
          if (this.alreadyUploadedFile.length !== 0) {
            const deleteResponse = await request({
              endpoint: `/api/file/scan/delete?fileId=${this.alreadyUploadedFile[0].fileId}`,
              // endpoint: `/api/moduledatagather/scan?scanId=${this.alreadyUploadedFile[0].fileId}`,
              method: 'GET',
              token: `${this.getAuthData.token}`
            });
            if (deleteResponse.status === 200) {
              console.log('удаление загруженного скана прошло успешно')
              const data = new FormData();
              data.append('file', this.fileToUpload)
              data.append('scanType', this.idTypeUploadedFile)
              if (this.isRegion) {
                data.append('regionId', this.getUser.regionID)
              } else {
                data.append('founderId', this.getUser.founderID)
              }
              const responseAxios = await axios.post(`/api/moduledatagather/scan/upload`, data,
                {
                  token: `${this.getAuthData.token}`
                })
              if (responseAxios.status === 200) {
                console.log('добавление скана прошло успешно')
                await this.addNotification({
                  notificationStatus: 'success',
                  notificationMessage: 'Файл успешно загружен'
                })
                this.uploadToServNow = false;
                await this.$router.push('/quotaContact'); // костыль
                await this.$router.push('/quotaPrint');
              } else {
                this.uploadToServNow = false;
                await this.addNotification({notificationStatus: 'error', notificationMessage: 'Ошибка загрузки файла'})
              }
            }
          } else {
            const data = new FormData();
            data.append('file', this.fileToUpload)
            data.append('scanType', this.idTypeUploadedFile)
            if (this.isRegion) {
              data.append('regionId', this.getUser.regionID)
            } else {
              data.append('founderId', this.getUser.founderID)
            }
            const responseAxios = await axios.post(`/api/moduledatagather/scan/upload`, data,
              {
                token: `${this.getAuthData.token}`
              })
            if (responseAxios.status === 200) {
              await this.addNotification({notificationStatus: 'success', notificationMessage: 'Файл успешно загружен'})
              this.uploadToServNow = false;
            } else {
              await this.addNotification({notificationStatus: 'error', notificationMessage: 'Ошибка загрузки файла'})
              this.uploadToServNow = false;
            }
            await this.$router.push('/quotaContact'); // костыль
            await this.$router.push('/quotaPrint');
          }
        }
      } else
        this.canUpload = false
    },
    async getUploadedFile() {
      this.uploadedFileLoading = true;
      this.downloadNow = true;
      await this.getUploadedScanView()
    },
    async getUploadedScanView() {
      let responseAxios = await axios.get(`/api/moduledatagather/scan/get?scanId=${this.alreadyUploadedFile[0].fileId}`, {
        responseType: 'blob',
        token: `${this.getAuthData.token}`
      })
      this.downloadNow = false;
      this.saveByteArray("ScanView.pdf", responseAxios.data);
      this.uploadedFileLoading = false;
    },
  },
}
</script>

<style lang="sass">
.border-active-content
  .collapse__toggle
    border-bottom: 1px solid #CFD8DC
    padding-bottom: 14px
</style>
